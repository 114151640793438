@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: inherit;
  height: inherit;

  #root {
    width: inherit;
    height: inherit;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.trioKombiLogo {
  max-height: 100px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: xx-large;
  font-weight: unset;
}

.trioKombiLogo {
  max-height: 100px;
}

.btn-alps {
  @apply bg-firstly border-firstly hover:bg-green hover:border-green;
}

.btn-alps-no-rounded {
  @apply bg-firstly border-firstly hover:bg-green hover:border-green;
}

.radio-alps {
  @apply border-firstly checked:border-firstly checked:bg-firstly hover:border-green;
}

.step-alps {
  @apply step-info;
  // &:after {
  //   @apply bg-green;
  // }
  // &:before {
  //   @apply bg-green;
  // }
}
